
import { ref, reactive, defineComponent, computed } from 'vue';

import {useToast} from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import FlexInput from '@/components/UI/Input.vue';

import { NomenclatureApi } from '@/classes/api/NomenclatureApi';
import NomenclatureFormFields from '@/constants/FormFields/nomenclature';
import {FormFields, FormStructure} from '@/interfaces/Input';
import {Nomenclature} from '@/classes/models/Nomenclature';
import {FormHandler} from '@/classes/forms/FormHandler';
import {MESSAGES} from '@/constants/errors';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  emits: ['update:visible', 'onSubmit'],
  components: {
    FlexInput,
    Dialog,
    Button,
  },
  props: {
    visible: Boolean,
    id: Number,
    editMode: Boolean,
  },
  setup(props, ctx) {
    const loading = ref(false);
    const item = reactive<Nomenclature>(new Nomenclature({}));
    const fields = reactive<FormFields>({ ...NomenclatureFormFields });

    const formHandler = new FormHandler(fields, item);
    const formStructure: FormStructure = formHandler.createFormStructure();
    const toast = useToast();
    const api = new NomenclatureApi();

    const inputVisible = computed({
      get: () => props.visible,
      set: val => {
        ctx.emit('update:visible', val);
      },
    });

    const resetValidations = () => {
      Object.values(fields).forEach(field => {
        field.invalid = false;
        field.validationErrors = [];
      });
    };

    const setItem = (itm: Nomenclature) => {
      item.replaceBy(itm);
      formHandler.setObjectToValidate(item);
    };

    const resetFormHandler = () => {
      setItem(new Nomenclature({}));
      resetValidations();
    };

    const fetch = async () => {
      if (props.editMode && props.id) {
        loading.value = true;
        try {
          setItem(await api.fetchById(props.id));
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      } else {
        resetFormHandler();
      }
    };

    const submitHandler = async () => {
      const valid = await formHandler.checkValidations();
      if (valid) {
        loading.value = true;
        try {
          if (!props.editMode) {
            await api.send(item);
          } else {
            await api.update(item);
          }
          ctx.emit('onSubmit');
          resetFormHandler();
          inputVisible.value = false;
          toast.add({
            severity: 'success',
            summary: 'Успешно',
            detail: 'Успешно добавлено',
            life: 3000,
          });
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Некорректное заполнение',
          detail: MESSAGES.generalFormErrors,
          life: 3000,
        });
      }
    };

    return {
      inputVisible,
      fetch,
      formStructure,
      item: item,
      fields: fields,
      formHandler,
      loading,
      resetFormHandler,
      submitHandler,
    };
  }
});
