import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  name: {
    title: 'Наименование',
    type: 'input',
  },
};

export default fields;
